

















import Vue from "vue";
import Nav from "./components/Navbar.vue";
import UpdateMixin from "./mixins/update";

export default Vue.extend({
  name: "App",
  mixins: [UpdateMixin],
  components: {
    Nav
  }
})
