
































































































import Vue from "vue"

const routes = { lotty: "indigo", gym: "cyan", goals: "teal"};

export default Vue.extend({
  name: "Workouts",
  props: ["days"],
  data: () => ({
    overlay: false,
    cOverlay: false,
    current: {},
  }),
  methods: {
    openOverlay(workout: any) {
      if (workout.type === "Cycle") {
        // @ts-ignore
        this.cOverlay = true;
        // @ts-ignore
        this.current = { cycles: workout.cycles, overline: workout.name };
      } else {
        // @ts-ignore
        this.overlay = true;
        // @ts-ignore
        this.current = {
          name: workout.name,
          sets: workout.sets,
          reps: workout.reps,
          img: require(`@/assets/${workout.name}.png`)
        }
      }
    }
  },
  computed: {
    color() {
      const route = this.$route.path.split("/");
      // @ts-ignore
      return routes[route[route.length - 1] || "gym"];
    }
  }
})
