













































import Vue from "vue"

const routes = { lotty: "indigo", gym: "cyan", goals: "teal"};

export default Vue.extend({
  name: "Navbar",
  data: () => ({
    items: [{
      title: "Lotty",
      icon: "mdi-face-woman-shimmer-outline",
      route: "/workouts/lotty"
    }, {
      title: "Goals",
      icon: "mdi-flag-variant-outline",
      route: "/workouts/goals"
    }, {
      title: "Gym",
      icon: "mdi-weight-lifter",
      route: "/workouts/gym"
    }]
  }),
  computed: {
    color() {
      const route = this.$route.path.split("/");
      // @ts-ignore
      return routes[route[route.length - 1] || "gym"];
    }
  }
})
